import { Box, Typography } from "@mui/material";

const EmptyScreen = ({ type }: any) => {
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <img
          src={EmptyImage}
          alt="Empty"
          style={{
            width: "40%", 
            height: "40%", 
            objectFit: "contain",
          }}
        />{" "} */}
        <Typography variant="h3" sx={{ marginTop: "19px", fontSize: "16px" }}>
          {`There are no ${type} added`}
        </Typography>
      </Box>
    </div>
  );
};

export default EmptyScreen;
