//@ts-nocheck
import { Box, Button, Card, Grid, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import {
  getDashboardAnalytics,
  getSessions,
  getTopFiveAnalytics,
} from "../../../api/services/api";
import { AnalyticsCardTemp } from "../../../components/AnalyticsCard/AnalyticsCard";
import BarChartCard from "../../../components/BarChartCard";
import ColorTags from "../../../components/ColorTags";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import textCapitalization from "../../../components/textCapitalization";

const Dashboard = () => {
  const theme = useTheme();
  const [status, setStatus] = useState(["PENDING", "APPROVED", "PAID"]);
  const [status2, setStatus2] = useState(["COMPLETED"]);

  const { data: analyticalData, isLoading } = useQuery(
    "dashboard-analytics",
    getDashboardAnalytics
  );

  if (isLoading) {
    <Loader />;
  }

  const { data: sessionData } = useQuery(
    [
      "sessions",
      {
        status: status,
      },
    ],
    getSessions
  );

  const topSessionData = sessionData?.data?.sessions.slice(0, 5);

  const { data: reportData } = useQuery(
    [
      "sessions",
      {
        status: status2,
      },
    ],
    getSessions
  );

  const topReportData = reportData?.data?.sessions?.slice(0, 5);

  const { data: top5Data } = useQuery("top5", getTopFiveAnalytics);

  const COLORS = ["#3E716A", "#D58E4E", "#77C19A"];

  const newColor = ["#684ED5", "#57CA22", "#D58E4E", "#FFA319"];

  const sessionColor = ["#FFA319", "#57CA22", "#D58E4E", "#684ED5", "#FF3465"];

  const TypeColor = [
    "#FF3465",
    "#684ED5",
    "#57CA22",
    "#FFA319",
    "#D58E4E",
    "#032961",
  ];

  const column = [
    {
      title: "Session ID",
      dataIndex: "sessionId",
      key: "sessionId",
    },

    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
      render: (colData: any, row: any) => colData,
    },
    {
      title: "Time slot",
      dataIndex: "time",
      key: "time",
      render: (colData: any, row: any) => (
        <Box display="flex" justifyContent="center">
          {row?.sessionTimeslots?.map((item: any) => (
            <Typography>
              {item?.timeslot?.startAt} - {item?.timeslot?.endAt}
            </Typography>
          ))}
        </Box>
      ),
    },

    {
      title: "Speciality",
      dataIndex: "clinician.designation",
      key: "clinician.designation",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (colData: any) => {
        return (
          <Button
            sx={{
              height: "40px",
              maxWidth: "120px",
              background:
                colData === "PENDING"
                  ? `${theme.colors.warning.lighter}`
                  : colData === "APPROVED"
                  ? `${theme.colors.success.lighter}`
                  : colData === "PAID"
                  ? `${theme.colors.info.lighter}`
                  : "",
              color:
                colData === "PENDING"
                  ? `${theme.colors.warning.main}`
                  : colData === "APPROVED"
                  ? `${theme.colors.success.main}`
                  : colData === "PAID"
                  ? `${theme.colors.info.main}`
                  : "",
            }}
          >
            {colData}
          </Button>
        );
      },
    },
  ];

  const column2 = [
    {
      title: "Session ID",
      dataIndex: "sessionId",
      key: "sessionId",
    },

    {
      title: "Client Name",
      dataIndex: "patientProfile.fullName",
      key: "patientProfile.fullName",
    },

    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
      render: (colData: any, row: any) => colData,
    },
    {
      title: "Time slot",
      dataIndex: "time",
      key: "time",
      render: (colData: any, row: any) => (
        <Box display="flex" justifyContent="center">
          {row?.sessionTimeslots?.map((item: any) => (
            <Typography>
              {item?.timeslot?.startAt} - {item?.timeslot?.endAt}
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (colData: any) => {
        return (
          <Button
            sx={{
              height: "40px",
              maxWidth: "120px",
              background:
                colData === "COMPLETED"
                  ? `${theme.colors.warning.lighter}`
                  : "",
              color:
                colData === "COMPLETED" ? `${theme.colors.warning.main}` : "",
            }}
          >
            PENDING
          </Button>
        );
      },
    },
  ];

  const clinicianColumn = [
    {
      title: "Name",
      dataIndex: "user.fullName",
      key: "user.fullName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (colData: any, row: any) => {
        return textCapitalization(colData || "");
      },
    },

    {
      title: "Speciality",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Total Sessions",
      dataIndex: "totalSessions",
      key: "totalSessions",
    },
  ];

  const patientColumn = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (colData: any, row: any) => {
        return textCapitalization(colData);
      },
    },

    {
      title: "Total Sessions",
      dataIndex: "totalSessions",
      key: "totalSessions",
    },

    {
      title: "Amount Spent by Client",
      dataIndex: "totalSessionAmount",
      key: "totalSessionAmount",
      render: (colData: any) => {
        return <Typography paddingRight={10}>{colData} SAR</Typography>;
      },
    },
  ];

  const barData = analyticalData?.data?.session?.statuses?.map((item: any) => {
    return {
      name: textCapitalization(item?.title),
      count: +item?.count,
    };
  });

  const barNamesAndColors = [
    {
      label: "Number of Sessions",
      color: "#D58E4E",
    },
  ];

  return (
    <Box mb={3} mt={1}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Sessions by User</Typography>
            </Box>
            <AnalyticsCardTemp
              number={analyticalData?.data?.user?.users || 0}
              title="Total Users"
              AvatarName="user"
              bg={theme.colors.warning.lighter}
              ChartColor={theme.colors.warning.main}
            />
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Sessions by Clinicians</Typography>
            </Box>
            <AnalyticsCardTemp
              number={analyticalData?.data?.clinician?.clinicians || 0}
              title="Total Clinicians"
              AvatarName="clinicians"
              AvatarColor={theme.colors.warning.main}
              bg={theme.colors.warning.lighter}
              ChartColor={theme.colors.warning.main}
            />
          </Card>
        </Grid>
        {/* <Grid item xs={4}>
          <Card>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Sessions by Patients</Typography>
            </Box>
            <AnalyticsCardTemp
              number={analyticalData?.data?.user?.patients || 0}
              title="Total Patients"
              AvatarName="patients"
              AvatarColor={theme.colors.warning.main}
              bg={theme.colors.warning.lighter}
              ChartColor={theme.colors.success.main}
            />
          </Card>
        </Grid> */}

        <Grid item xs={4}>
          <Card>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Total Number of Sessions</Typography>
            </Box>
            <AnalyticsCardTemp
              number={analyticalData?.data?.session?.total || 0}
              title="Total Sessions By Users"
              AvatarName=""
              AvatarColor={theme.colors.warning.main}
              bg={theme.colors.warning.lighter}
              ChartColor={theme.colors.warning.main}
            />
          </Card>
        </Grid>
      </Grid>

      <Grid mt={3} container spacing={2}>
        <Grid item xs={6}>
          <Card>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Sessions by Gender</Typography>
            </Box>
            <Box p={1.5} width="100%" height="230px" display="flex">
              {analyticalData?.data?.user?.gender.every(
                (item) => item.count === 0
              ) ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="70%"
                >
                  <Typography variant="h3">No Data Available...</Typography>
                </Box>
              ) : (
                <>
                  <ResponsiveContainer width="65%" height="100%">
                    <PieChart>
                      <Pie
                        data={analyticalData?.data?.user?.gender}
                        dataKey="count"
                        nameKey="title"
                        cx="40%"
                        cy="50%"
                        innerRadius={70}
                        outerRadius={90}
                        fill="color"
                      >
                        {analyticalData?.data?.user?.gender.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          )
                        )}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </>
              )}
              <Box
                display="flex"
                flexDirection="column"
                gap={1.5}
                justifyContent="center"
              >
                <ColorTags color="#3E716A" tag="Male" />
                <ColorTags color="#D58E4E" tag="Female" />
                <ColorTags color="#77C19A" tag="Others" />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ width: "100%" }}>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Sessions By Consultation Type</Typography>
            </Box>
            <Box width="100%" height="230px" display="flex">
              <ResponsiveContainer width="70%" height="100%">
                <PieChart>
                  <Pie
                    data={analyticalData?.data?.session?.modes}
                    dataKey="count"
                    nameKey="title"
                    cx="40%"
                    cy="50%"
                    innerRadius={70}
                    outerRadius={90}
                    fill="color"
                  >
                    {analyticalData?.data?.session?.modes.map(
                      (entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      )
                    )}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <Box
                display="flex"
                flexDirection="column"
                gap={1.5}
                justifyContent="center"
              >
                <ColorTags color="#3E716A" tag="Audio" />
                <ColorTags color="#D58E4E" tag="Video" />
                <ColorTags color="#77C19A" tag="At Home" />
              </Box>
            </Box>
          </Card>
        </Grid>
        {/* <Grid item xs={6}>
          <Card sx={{ width: "100%" }}>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Sessions by Experience</Typography>
            </Box>
            <Box width="100%" height="230px" display="flex">
              {analyticalData?.data?.clinician?.experience.every(
                (item) => item.count === 0
              ) ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="70%"
                >
                  <Typography variant="h3">No Data Available...</Typography>
                </Box>
              ) : (
                <>
                  <ResponsiveContainer width="65%" height="100%">
                    <PieChart>
                      <Pie
                        data={analyticalData?.data?.clinician?.experience}
                        dataKey="count"
                        nameKey="title"
                        cx="40%"
                        cy="50%"
                        outerRadius={100}
                        fill="#57CA22"
                      >
                        {analyticalData?.data?.clinician?.experience.map(
                          (entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={newColor[index % newColor.length]}
                            />
                          )
                        )}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </>
              )}
              <Box
                display="flex"
                flexDirection="column"
                gap={1.5}
                justifyContent="center"
              >
                <ColorTags color="#684ED5" tag="0 - 10" />
                <ColorTags color="#57CA22" tag="11 - 15" />
                <ColorTags color="#D58E4E" tag="16 - 30 Years" />
                <ColorTags color="#FFA319" tag=">30" />
              </Box>
            </Box>
          </Card>
        </Grid> */}
      </Grid>
      <Typography mb={2} mt={4} variant="h4">
        Session Analytics
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card sx={{ width: "100%" }}>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Sessions by Status</Typography>
            </Box>
            <BarChartCard
              layout="vertical"
              data={barData}
              barNamesAndColors={barNamesAndColors}
              dataSource={analyticalData?.data?.session?.statuses}
            />
          </Card>
          {/* <Card sx={{ width: "100%" }}>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Session Analytics</Typography>
            </Box>
            <Box pt={1} width="100%" height="215px" display="flex">
              <ResponsiveContainer
                width="99%"
                height="98%"
                border="1px solid red"
              >
                <BarChart
                  data={analyticalData?.data?.session?.statuses}
                  layout="vertical"
                  barCategoryGap={23}
                  margin={{ top: 8, right: 50, left: 70, bottom: 0 }}
                >
                  <XAxis type="number" dataKey="count" />
                  <YAxis type="category" dataKey="title" />
                  <Tooltip />
                  <Bar dataKey="count" fill="#FFA319" radius={10}>
                    {analyticalData?.data?.session?.statuses.map(
                      (entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={sessionColor[index % sessionColor.length]}
                        />
                      )
                    )}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Card> */}
        </Grid>
      </Grid>
      <Grid mt={2} container spacing={2}>
        <Grid item xs={6}>
          {/* <Card sx={{ width: "100%" }}>
            <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
              <Typography>Session Analytics</Typography>
            </Box>
            <Box width="100%" height="230px" display="flex">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={analyticalData?.data?.session?.types}
                    dataKey="count"
                    nameKey="title"
                    cx="50%"
                    cy="50%"
                    outerRadius={90}
                    fill="#57CA22"
                  >
                    {analyticalData?.data?.session?.types.map(
                      (entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={TypeColor[index % TypeColor.length]}
                        />
                      )
                    )}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Card> */}
        </Grid>
      </Grid>
      <Grid container spacing={2} mt="10px">
        <Grid item xs={12}>
          <Box
            p={2}
            sx={{ backgroundColor: "#fdf9f6", borderRadius: "10px 10px 0 0" }}
          >
            <Typography>Upcomming Sessions</Typography>
          </Box>
          <Table
            headAlign={"center"}
            tableAlign={"center"}
            key={"sessions"}
            dataSource={sessionData?.data?.count === 0 ? null : topSessionData}
            rowKey={"id"}
            columns={column}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            p={2}
            sx={{ backgroundColor: "#fdf9f6", borderRadius: "10px 10px 0 0" }}
          >
            <Typography>Pending Reports</Typography>
          </Box>
          <Table
            headAlign={"center"}
            tableAlign={"center"}
            key={"sessions"}
            dataSource={reportData?.data?.count === 0 ? null : topReportData}
            rowKey={"id"}
            columns={column2}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} mt="30px">
        <Grid item xs={6}>
          <Box
            p={2}
            sx={{ backgroundColor: "#fdf9f6", borderRadius: "10px 10px 0 0" }}
          >
            <Typography>Top 5 Clinicians</Typography>
          </Box>
          <Table
            headAlign={"center"}
            tableAlign={"center"}
            key={"sessions"}
            dataSource={
              top5Data?.data?.clinicians.length === 0
                ? null
                : top5Data?.data?.clinicians
            }
            rowKey={"id"}
            columns={clinicianColumn}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            p={2}
            sx={{ backgroundColor: "#fdf9f6", borderRadius: "10px 10px 0 0" }}
          >
            <Typography>Top 5 Patients</Typography>
          </Box>
          <Table
            headAlign={"center"}
            tableAlign={"center"}
            key={"sessions"}
            dataSource={
              top5Data?.data?.patientsProfiles.length === 0
                ? null
                : top5Data?.data?.patientsProfiles
            }
            rowKey={"id"}
            columns={patientColumn}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
