import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getPatients } from "../../../api/services/api";
import EmptyScreen from "../../../components/EmptyScreen";
import { ViewButton } from "../../../components/IconButtons";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import textCapitalization from "../../../components/textCapitalization";

const Patients = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [search, setSearch] = useState("");
  const [gender, setGender] = useState<string>("");
  const [age, setAge] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { data, isLoading } = useQuery(
    [
      "patients",
      {
        search: search,
        limit: limit,
        offset: limit * page,
        gender: gender,
        age: age,
      },
    ],
    getPatients
  );

  const openDialog = Boolean(anchorEl);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClearFilter = () => {
    setAge("");
    setGender("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user.fullName",
      key: "user.fullName",
    },

    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },

    {
      title: "Mobile Number",
      dataIndex: "user.mobileNumber",
      key: "user.mobileNumber",
    },
    {
      title: "Email Address",
      dataIndex: "user",
      key: "user",
      render: (colData: any) => {
        return colData?.email ? colData?.email : "N/A";
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (colData: any, row: any) => (
        <ViewButton
          onClick={() => {
            navigate(`/patients/details/${row?.id}/dashboard`);
          }}
        />
      ),
    },
  ];

  const genderData = ["MALE", "FEMALE", "OTHERS"];

  const ageData = ["1-10", "11-20", "21-40", "41-60", ">60"];

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          mt: "20px",
        }}
      >
        <Box sx={{ display: "flex" }} gap={"26px"}>
          <Typography variant="h3">Clients</Typography>
        </Box>
        <Box display="flex" gap="20px">
          <TextField
            label="Search by Client Name"
            sx={{ width: "450px", padding: "0px" }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button
            variant="outlined"
            startIcon={<FilterListOutlinedIcon />}
            onClick={handleClick}
          >
            <Typography>Filter</Typography>
          </Button>
          <Button
            onClick={handleClearFilter}
            sx={{
              whiteSpace: "nowrap",
              fontWeight: "bold",
              ml: -2,
              "&:hover": { backgroundColor: "transparent" },
            }}
            disableRipple
            disableElevation
            disableFocusRipple
            disableTouchRipple
          >
            Clear Filter
          </Button>
          <Menu
            open={openDialog}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <MenuItem sx={{ width: "350px" }}>
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  options={genderData}
                  value={gender}
                  getOptionLabel={(option) => textCapitalization(option)}
                  onChange={(event, newValue) => {
                    setGender(newValue || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Select Gender"
                    />
                  )}
                />
              </Box>
            </MenuItem>
            <MenuItem>
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  options={ageData}
                  getOptionLabel={(option) => option}
                  value={age}
                  onChange={(event, newValue) => {
                    setAge(newValue?.split(">").join("") || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Select Age"
                    />
                  )}
                />
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {data?.data.count === 0 ? (
        <EmptyScreen type={"Patients"} />
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              key={"Patients"}
              dataSource={data?.data?.patients}
              rowKey={"id"}
              columns={columns}
              loading={isLoading}
            />
          )}
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TablePagination
              count={data?.data.count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
            {/* <Pagination
              count={count > 10 ? Math.ceil(count / 10) : 1}
              variant="outlined"
              shape="rounded"
              onChange={handlePagination}
              page={page}
              // color={`${theme?.palette?.primary?.light}`}
            /> */}
          </Box>
        </>
      )}
    </div>
  );
};

export default Patients;
