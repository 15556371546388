import { Box, Typography } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const BarChartCard = ({ data, barNamesAndColors, layout, dataSource }: any) => {
  const sessionColor = ["#FFA319", "#57CA22", "#D58E4E", "#684ED5", "#FF3465"];

  const formatter = (value: any) => `${(value / 1000).toFixed(1) + "K"}`;
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            padding: "7px",
            backgroundColor: "white",
            border: "none",
            borderRadius: "5px",
            boxShadow: "0px 3px 13px #2A32711A",
          }}
        >
          <Typography variant="subtitle2" sx={{ opacity: "0.5" }}>
            {payload[0].name}{" "}
          </Typography>
          <Box display="flex">
            <Typography variant="h6">
              {payload[0].value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </Typography>
            {payload[0]?.payload?.currency && (
              <Typography ml={1} variant="h6">
                {payload[0]?.payload?.currency}
              </Typography>
            )}
          </Box>
        </div>
      );
    }

    return null;
  };

  const renderLegend = (props: any) => {
    const { payload } = props;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "7px",
        }}
      >
        {payload.map((entry: any, index: any) => (
          <span
            style={{ padding: "10px", display: "flex", alignItems: "center" }}
            key={`item-${index}`}
          >
            <div
              style={{
                display: "inline-block",
                width: "12px",
                marginRight: "4px",
                height: "12px",
                borderRadius: "999px",
                backgroundColor: entry.color,
              }}
            ></div>
            <Typography>{entry.value}</Typography>
          </span>
        ))}
      </div>
    );
  };
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        layout={layout}
        margin={{
          top: 45,
          right: 20,
          left: 10,
          bottom: 10,
        }}
        barSize={10}
      >
        <XAxis
          dataKey={layout === "vertical" ? "count" : "name"}
          type={layout === "vertical" ? "number" : "category"}
          ticks={layout === "vertical" ? [0, 50, 100, 150, 200] : undefined}
          padding={{ left: 20, right: 50 }}
          axisLine={false}
          tickLine={false}
          style={{
            fontSize: "12px",
          }}
        />
        <YAxis
          dataKey={layout === "vertical" ? "name" : "count"}
          type={layout === "vertical" ? "category" : "number"}
          // tickCount={11}
          tickFormatter={layout === "vertical" ? undefined : formatter}
          tickLine={false}
          axisLine={false}
          width={130}
          style={{
            fontSize: "18px",
            fontFamily: "Inter_semibold",
            // opacity: "0.7",
          }}
        />
        <Tooltip shared={false} content={<CustomTooltip />} />
        <Legend content={renderLegend} iconType={"circle"} />
        <CartesianGrid stroke="#f5f5f5" vertical={false} />
        {barNamesAndColors?.map((item: any, index: any) => (
          <Bar
            key={index}
            name={item?.label}
            dataKey={layout === "vertical" ? "count" : item?.label}
            fill={item.color}
            // background={{ fill: "#eee" }}
            radius={[10, 10, 10, 10]}
          ></Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartCard;
