//@ts-nocheck
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getClinicians } from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import { EditButton, ViewButton } from "../../../components/IconButtons";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import textCapitalization from "../../../components/textCapitalization";
import TherapistsEmptyScreen from "./TherapistsEmptyScreen";
import TherapistsForm from "./TherapistsForm";

const Therapists = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [gender, setGender] = useState("");
  const [speciality, setSpeciality] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { data, isLoading } = useQuery(
    [
      "therapists",
      {
        search: search,
        limit: limit,
        offset: limit * page,
        gender: gender,
        designation: speciality,
      },
    ],
    getClinicians
  );

  const openDialog = Boolean(anchorEl);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = (row: any) => {
    setFormData(row);
    toggleDrawer(true);
  };

  const handleClearFilter = () => {
    setGender("");
    setSpeciality("");
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user.fullName",
      key: "user.fullName",
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (colData) => {
        return textCapitalization(colData || "");
      },
    },

    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      render: (_, colData) => {
        return colData?.designation ? colData?.designation : "NA";
      },
    },

    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (colData: any, row: any) => (
        <Box display="flex" gap="20px">
          <EditButton
            onClick={() => {
              handleEditClick(row);
            }}
          />
          <ViewButton
            onClick={() => navigate(`details/${row?.id}/dashboard`)}
          />
        </Box>
      ),
    },
  ];

  const genderData = ["MALE", "FEMALE", "OTHERS"];

  const designationData = ["Therapist", "Language Therapy"];

  return (
    <div>
      {" "}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }} gap={"26px"}>
          {/* <Typography variant="h3">Therapists</Typography> */}
          <Button
            onClick={() => {
              setFormData(null);
              toggleDrawer(true);
            }}
            variant="contained"
          >
            + Add Clinician
          </Button>
        </Box>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <StyledDrawerHeader>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter_semibold",
                color: "black",
              }}
            >
              {formData ? "Edit Therapist" : "Add Therapist"}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter_semibold",
                color: "black",
                opacity: "0.5",
                cursor: "pointer",
              }}
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Typography>
          </StyledDrawerHeader>
          <StyledDrawerContent>
            <Box sx={{ width: "550px" }}>
              <TherapistsForm data={formData} toggleDrawer={toggleDrawer} />
            </Box>
          </StyledDrawerContent>
        </Drawer>
        <Box sx={{ display: "flex" }} gap={"20px"}>
          <TextField
            label="Search by Clinician Name"
            sx={{ width: "450px", padding: "0px" }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button
            variant="outlined"
            startIcon={<FilterListOutlinedIcon />}
            onClick={handleClick}
          >
            <Typography>Filter</Typography>
          </Button>
          <Button
            onClick={handleClearFilter}
            sx={{
              whiteSpace: "nowrap",
              fontWeight: "bold",
              ml: -2,
              "&:hover": { backgroundColor: "transparent" },
            }}
            disableRipple
            disableElevation
            disableFocusRipple
            disableTouchRipple
          >
            Clear Filter
          </Button>
          <Menu
            open={openDialog}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          >
            <MenuItem sx={{ width: "350px" }}>
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  options={genderData}
                  value={gender}
                  getOptionLabel={(option) => textCapitalization(option)}
                  onChange={(event, newValue) => {
                    setGender(newValue || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Select Gender"
                    />
                  )}
                />
              </Box>
            </MenuItem>
            <MenuItem>
              <Box sx={{ width: "100%" }}>
                <Autocomplete
                  options={designationData}
                  getOptionLabel={(option) => option}
                  value={speciality}
                  onChange={(event, newValue) => {
                    setSpeciality(newValue || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      label="Select Speciality"
                    />
                  )}
                />
              </Box>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
      {data?.data?.count === 0 ? (
        <TherapistsEmptyScreen />
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <Table
              key={"Therapists"}
              dataSource={data?.data?.clinicians}
              rowKey={"id"}
              columns={columns}
              loading={isLoading}
            />
          )}
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TablePagination
              count={data?.data.count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default Therapists;
