import { AppBar, Box, Typography } from "@mui/material";
import { NavLink, Outlet } from "react-router-dom";

const Resources = () => {
  return (
    <div>
      <AppBar
        style={{
          // position: 'absolute',
          top: 90,
          left: "270px",
          backgroundColor: "#F4F4F4",
          boxShadow: "none",
        }}
      >
        <nav
          style={{
            display: "flex",
            paddingLeft: "38px",
          }}
        >
          <NavLink className={"resourcesNavLink"} to={"/resources"} end>
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Videos
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink className={"resourcesNavLink"} to={"/resources/articles"}>
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Articles
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
        </nav>
      </AppBar>
      <Box sx={{ marginTop: "50px" }}>
        <Outlet />
      </Box>
    </div>
  );
};

export default Resources;
