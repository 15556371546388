import { AppBar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { NavLink, Outlet, useParams } from "react-router-dom";

function PatientDetails() {
  const id = useParams();

  return (
    <div>
      <AppBar
        style={{
          // position: 'absolute',
          top: 95,
          left: "270px",
          backgroundColor: "#F4F4F4",
          boxShadow: "none",
        }}
      >
        <nav
          style={{
            display: "flex",
            paddingLeft: "38px",
          }}
        >
          <NavLink
            className={"resourcesNavLink"}
            to={`/patients/details/${id?.id}/dashboard`}
            end
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Dashboard
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={"resourcesNavLink"}
            to={`/patients/details/${id?.id}/basic-details`}
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Basic Details
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={"resourcesNavLink"}
            to={`/patients/details/${id?.id}/sessions`}
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Sessions
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          <NavLink
            className={"resourcesNavLink"}
            to={`/patients/details/${id?.id}/reports`}
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Reports
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink>
          {/* <NavLink
            className={"resourcesNavLink"}
            to={`/patients/details/${id?.id}/reviews`}
          >
            <Typography
              className="resourcesNavLinkText"
              sx={{ fontSize: "15px", fontFamily: "Inter_semibold" }}
            >
              Reviews
            </Typography>
            <Box id="borderBottom"></Box>
          </NavLink> */}
        </nav>
      </AppBar>
      <Box sx={{ marginTop: "50px" }}>
        <Outlet />
      </Box>
    </div>
  );
}

export default PatientDetails;
