//@ts-nocheck
import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import { EmptyImage } from "../../../assets";

const TherapistsEmptyScreen = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };
  return (
    <div>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // marginTop: "180px",
        }}
      >
        {/* <img
          src={EmptyImage}
          style={{
            marginTop: "10%",
            width: "30%",
            height: "auto",
          }}
        /> */}
        <Typography variant="h3" sx={{ marginTop: "19px", fontSize: "16px" }}>
          {`There are no Therapists added`}
        </Typography>
      </Box>
    </div>
  );
};

export default TherapistsEmptyScreen;
